import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';

import { EMMALoadingModule } from './emma-loading.module';
import { EMMAMissingAlertModule } from './emma-missing-alert.module';
import { EMMAPortletComponent } from './emma-portlet.component';
import { EMMAInfoIconModule } from '@emma-components/emma-info-icon.module';

@NgModule({
  imports: [
    CommonModule,
    EMMAMissingAlertModule,
    EMMALoadingModule,
    EMMADirectivesModule,
    NgbModule,
    EMMAInfoIconModule,
  ],
  declarations: [EMMAPortletComponent],
  exports: [EMMAPortletComponent, NgbModule],
})
export class EMMAPortletModule {}
